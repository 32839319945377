import { FC } from 'react'
import { Link, useMatch } from 'react-router-dom'
import Typography from '@mui/material/Typography'

import s from '../../styles'
import { useTranslation } from 'react-i18next'


export interface MenuLinkProps {
  label: string
  path: string
  disabled?: boolean
}

const MenuLink: FC<MenuLinkProps> = ({label, path, disabled}) => {
  const {t} = useTranslation()
  const match = useMatch({
    path: path,
    end: path === '/' ? true : false,
  })

  return (
    <Typography sx={s.link(!!match, !!disabled)}>
      <Link to={path}> {t(label, '')} </Link>
    </Typography>
  )
}

export default MenuLink
