import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getTimeToNextInterval } from 'utils/helpers';


const CircularProgressTimer = () => {
  const [timeLeft, setTimeLeft] = useState(getTimeToNextInterval());

  useEffect(() => {
    const id = setInterval(() => {
      setTimeLeft(getTimeToNextInterval());
    }, 1000);
    return () => clearInterval(id);
  }, []);

  const circularColor = timeLeft < 5 ? 'declined.main' : 'primary.main'

  return (
    <Box sx={{ display: 'flex',alignItems: 'center', p: '4px 8px'}}>
      <CircularProgress
        variant="determinate"
        value={(30 - timeLeft / 30) * 100}
        size={32}
        thickness={22}
        sx={{
          color: circularColor,
          transform: 'rotate(180deg)',
          boxSizing: 'border-box',
          margin: '0 auto'
        }}
      />
    </Box>
  );
};

export default CircularProgressTimer;
