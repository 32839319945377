import { FC } from 'react'
import { Box, InputLabel, Select, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'

import { theme } from 'theme'
import { useMediaSize } from 'hooks/useMediaSize'
import { useTranslation } from 'react-i18next'


export interface Option {
  label: string
  value: string
}

interface Props {
  value: string
  setValue: (value: string) => void
  options: Option[]
  disabled?: boolean
}

const SidebarSelect: FC<Props> = ({
  value,
  setValue,
  options,
  disabled,
}) => {
  const { t } = useTranslation()
  const {isMobileDevice} = useMediaSize()

  const handleChange =  (event: any) => {
    setValue(event.target.value);
  };

  return (
    <Box >
      {!isMobileDevice &&
        <InputLabel
          sx={{
            color: theme => theme.palette.text.contrast,
            fontWeight:'400',
            fontSize:'14px',
            mb:'12px',
            mt:'24px'
          }}
        >
          {t('Currency')}
        </InputLabel>
      }
      <Select
        value={value || ''}
        onChange={handleChange}
        className='inputRounded'
        size='small'
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: '260px',
              overflowY: 'scroll',
              paddingRight: '12px',
              border: `1px solid ${theme.palette.text.contrast}`,
              '& .MuiMenuItem-root': {
                fontWeight: 600,
              },
            },
          },
        }}
        sx={{
          width: isMobileDevice ? '164px' : '100%',
          color: theme.palette.text.contrast,
          mb: isMobileDevice ? 0 : '24px',
          fontWeight: 600,
          '&:hover, &.Mui-focused': {
            '&& fieldset': {
              borderColor: theme.palette.text.contrast
            }
          },
          '& .MuiOutlinedInput-input:focus': {
            borderRadius: '8px',
          },
          '& .MuiOutlinedInput-input': {
              border: `0.5px solid ${theme.palette.text.contrast}`,
            },
          '& .MuiSelect-icon': {
            color: theme.palette.text.contrast,
          },
        }}
      >
        {options.length ?
          options?.map((o, index) => (
            <MenuItem key={index} value={o.value} sx={{display: 'flex', justifyContent: 'space-between'}}>
              {o.label}
            </MenuItem>
          ))
          :
          <Typography textAlign='center' variant='h5'>{t('NoCurrencies')}</Typography>
        }
      </Select>
    </Box>
  )
}

export default SidebarSelect