import { Currency, CurrencyType } from 'types/currency'
import { OperationsFilterParams, WithdrawFilterParams } from 'types/operation'
import { TemplateWithdrawCreate, WithdrawDataParams } from 'types/template'


export const adaptOperationsFilterValues = (
  obj: OperationsFilterParams
): OperationsFilterParams => {
  const adaptObj = { ...obj }

  if (!adaptObj.status) {
    adaptObj.status = 'none'
  }

  if (!adaptObj.type) {
    adaptObj.type = 'none'
  }
  if (adaptObj.dateFrom) {
    adaptObj.dateFrom = new Date(adaptObj.dateFrom).toISOString()
  }
  if (adaptObj.dateTo) {
    adaptObj.dateTo = new Date(adaptObj.dateTo).toISOString()
  }

  return adaptObj
}

export const adaptWithdrawFilterValues = (
  obj: WithdrawFilterParams
): WithdrawFilterParams => {
  const adaptObj = { ...obj }

  if (!adaptObj.status) {
    delete adaptObj.status
  }

  if (adaptObj.dateFrom) {
    adaptObj.dateFrom = new Date(adaptObj.dateFrom).toISOString()
  }
  if (adaptObj.dateTo) {
    adaptObj.dateTo = new Date(adaptObj.dateTo).toISOString()
  }

  return adaptObj
}

export const getCurrenciesByType = (
  currencies: Currency[] = []
): CurrencyType => {
  const initCurrencyType: CurrencyType = {
    fiat: [],
    crypto: [],
  }
  return currencies.reduce((acc, currency) => {
    currency.isCrypto
      ? acc.crypto.push(currency.code)
      : acc.fiat.push(currency.code)
    return acc
  }, initCurrencyType)
}

export const countDecimals = (value: number | string) => {
  const current = +value
  if (isNaN(current)) return 0
  if (Math.floor(current) === current) return 0
  return current.toString().split('.')[1]?.length || 0
}

export const getWithdrawData = ({
  formData,
  wallet,
  psCode,
}: WithdrawDataParams): TemplateWithdrawCreate => {
  return {
    walletId: wallet.id,
    currency: wallet.currency,
    psCode,
    amount: +formData.amount,
    comment: formData.comment ?? '',
    paymentFormDefinition: formData,
  }
}

export const getTimeToNextInterval = () => {
  const currentSeconds = new Date().getSeconds();
  const remainder = currentSeconds % 30;
  return remainder === 0 ? 30 : 30 - remainder;
};