import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { Option } from 'components/Select'
import WalletTabs from 'components/WalletTabs'
import AddWalletModal from 'components/AddWalletModal'
import SidebarCheckbox from './SidebarCheckbox'
import SidebarSelect from './SidebarSelect'
import Preloader from 'components/Preloader'
import WalletsPreloader from './WalletsPreloader'
import WalletsEmptyList from './WalletsEmptyList'

import { useFetchAllWalletsQuery, useGetWalletsCurrenciesQuery } from 'services/wallets'
import { useMediaSize } from 'hooks/useMediaSize'
import { useGetCurrencyQuery } from 'services/currency'
import { useTranslation } from 'react-i18next'


const WalletsNavigation = () => {
  const { t } = useTranslation()
  const [showAddWalletModal, setShowAddWalletModal] = useState(false)
  const { isMobileDevice } = useMediaSize()

  const [searchParams, setSearchParams] = useSearchParams()
  const currency = searchParams.get('currency') || ''
  const excludeEmpty = searchParams.get('excludeEmpty') === 'true'
  const wallet = searchParams.get('wallet')

  const { data: currencies = [], isFetching: isCurrenciesFetching, isSuccess: isCurrenciesSuccess } = useGetWalletsCurrenciesQuery()
  const { data: wallets = [], isLoading, isFetching, isSuccess, refetch } = useFetchAllWalletsQuery(currency ? { currency, excludeEmpty } : skipToken, {refetchOnMountOrArgChange: true})
  const { data: currencyInfo, isLoading: isCurrencyLoading } = useGetCurrencyQuery(currency || skipToken)

  useEffect(() => {
    wallet && refetch()
  }, [excludeEmpty])

  useEffect(() => {
    if (currencies.length && !currency) {
      const newCurrency = currencies[0].code
      setSearchParams({ currency: newCurrency, excludeEmpty: excludeEmpty.toString() })
    }
  }, [currencies, currency])

  useEffect(() => {
    if (isSuccess && !wallets.length) {
      setSearchParams(prevParams => {
        const params = new URLSearchParams(prevParams)
        params.delete('wallet')
        return params
      })
    }
  }, [wallets, wallet, isFetching])

  useEffect(() => {
    if ((!wallet || !wallets.find(w => w.id === wallet)) && isSuccess && wallets.length > 0) {
      setSearchParams(prevParams => {
        const params = new URLSearchParams(prevParams)
        const prevWalletId = wallets.find(w => w.id === prevParams.get('wallet')) && prevParams.get('wallet')
        const initialWalletId = wallets[0].id
        params.set('wallet', prevWalletId || initialWalletId)
        return params
      })
    }
  }, [wallets])

  const currenciesOptions: Option[] = useMemo(
    () => currencies?.map((currency) => ({ label: currency.code, value: currency.code })),
    [currencies]
  )

  const isCurrenciesEmpty = useMemo(
    () => !currencies.length && isCurrenciesSuccess,
    [isCurrenciesSuccess]
  )

  const handleCurrencyChange = (value: string) => {
    setSearchParams(prevParams => {
      const params = new URLSearchParams(prevParams)
      params.set('currency', value)
      params.delete('wallet')
      return params
    })
  }

  const handleExcludeEmptyChange = (checked: boolean) => {
    setSearchParams(prevParams => {
      const params = new URLSearchParams(prevParams)
      params.set('excludeEmpty', checked.toString())
      return params
    })
  }

  if ( isLoading || isCurrencyLoading || isCurrenciesFetching) return <Preloader withLeftMargin/>

  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'block' },
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: isCurrenciesEmpty ? 'end' : 'space-between',
        p: { xs: '16px', sm: '20px', md: '24px' },
        gap: { xs: '16px', sm: '20px' },
      }}
    >
      <Button
        onClick={() => setShowAddWalletModal(true)}
        variant="textOutlined"
        endIcon={<AddIcon fontSize="small" sx={{ color: 'primary.main' }} />}
        sx={{
          '& .MuiButton-endIcon': { ml: '2px' },
          width: isMobileDevice ? 'auto' : '100%',
        }}
      >
        <Typography color="primary" textTransform="none" fontWeight={500}>
          {t('Modals.Wallets.AddNew')}
        </Typography>
      </Button>
      <Box
        sx={{
          display: { xs: 'flex', md: 'block' },
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: { xs: '16px', sm: '20px' },
        }}
      >
        <SidebarSelect value={currency} setValue={handleCurrencyChange} options={currenciesOptions} />
        <SidebarCheckbox checked={excludeEmpty} setChecked={handleExcludeEmptyChange} />
      </Box>
      {!isMobileDevice && (
        isFetching
          ? <WalletsPreloader/>
          : (wallets.length && wallets.find(w => w.id === wallet) && wallets[0].currency === currencyInfo?.code)
            ? <WalletTabs wallets={wallets} currencyInfo={currencyInfo}/>
            : (!wallets.length && <WalletsEmptyList/>)
      )}
      {showAddWalletModal ? <AddWalletModal handleClose={() => setShowAddWalletModal(false)} /> : null}
    </Box>
  )
}

export default WalletsNavigation