import TableCell from '@mui/material/TableCell'
import TableHeader from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'

import { OperationField } from 'assets/const'

import { OperationsPagingParams } from 'types/operation'
import s from '../styles'
import { useTranslation } from 'react-i18next'


interface HeadCell {
  disablePadding: boolean
  id: keyof typeof OperationField
  label: string
  align: 'left' | 'right' | 'center'
  isSortable?: boolean
}
interface Props {
  pagingParams: OperationsPagingParams
  setPagingParams: (params: Partial<OperationsPagingParams>) => void
}

const TableHead = ({ pagingParams, setPagingParams }: Props) => {
  const { t } = useTranslation()
  const handleSort = (newSortField: keyof typeof OperationField) => {
    const isAsc =
      pagingParams.sortField === newSortField &&
      pagingParams.sortDirection === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'

    setPagingParams({ sortDirection: toggledOrder, sortField: newSortField })
  }


  const headCells: HeadCell[] = [
    {
      id: 'created',
      align: 'center',
      disablePadding: true,
      label: t('OperationTable.Date'),
      isSortable: true,
    },
    {
      id: 'type',
      align: 'center',
      disablePadding: true,
      label: t('OperationTable.Operation'),
      isSortable: true,
    },
    {
      id: 'amount',
      align: 'center',
      disablePadding: true,
      label: t('OperationTable.Amount'),
      isSortable: true,
    },
    {
      id: 'status',
      align: 'center',
      disablePadding: true,
      label: t('OperationTable.Status'),
      isSortable: true,
    },
    {
      id: 'description',
      align: 'center',
      disablePadding: true,
      label: t('OperationTable.Description'),
      isSortable: true,
    },
  ]

  return (
    <TableHeader>
      <TableRow sx={{ ...s.row, ...s.operationRowGrid, ...s.shadowRow }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={
              pagingParams.sortField === headCell.id
                ? pagingParams.sortDirection
                : false
            }
          >
            {headCell.isSortable ? (
              <TableSortLabel
                sx={{ ml: headCell.align === 'center' ? '20px' : 0 }}
                active={pagingParams.sortField === headCell.id}
                direction={
                  pagingParams.sortField === headCell.id
                    ? pagingParams.sortDirection
                    : 'asc'
                }
                title={`sorting by ${headCell.label}`}
                onClick={() => {
                  handleSort(headCell.id)
                }}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  )
}

export default TableHead