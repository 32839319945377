import { useCallback, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import i18n from 'i18n'
import MenuIcon from '@mui/icons-material/Menu';

import UserInfo from './UserInfo'
import MenuLink from './UserInfo/MenuLink'
import MobileMenuLink from './UserInfo/MobileMenuLink'

import { useMediaSize } from 'hooks/useMediaSize'
import { DrawerVariantType } from 'types/drawer'

import { AppRoute } from 'assets/const'
import { ReactComponent as Logo } from 'assets/svg/logo/LogoCwSecondary.svg'
import { ReactComponent as LogoIcon } from 'assets/svg/logo/LogoCwIcon.svg'

import s from './styles'
import i18next from 'i18next'


interface Props {
  drawerVariant: DrawerVariantType | null
}

export const menuItems = [
  {label: 'HeaderMenu.Home', path: AppRoute.Home},
  {label: 'HeaderMenu.Payments', path: '#', disabled: true},
  {label: 'HeaderMenu.FAQ', path: AppRoute.Faq},
  {label: 'HeaderMenu.More', path: AppRoute.More},
]

const Header = ({ drawerVariant }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const { isMobileDevice } = useMediaSize()
  const { isPhone } = useMediaSize()

  const handleToggle = () => {
    setAnchorEl((prev) => (prev ? null : containerRef.current))
  }

  const [lang, setLang] = useState(
    localStorage.getItem('cleverWalletLanguage') || 'en'
  )

  const setLanguage = useCallback(
    async (e: SelectChangeEvent) => {
      const lang = e.target.value
      setLang(lang)
      localStorage.setItem('cleverWalletLanguage', lang)
      await i18n.changeLanguage(lang)
    },
    [i18n]
  )

  return (
    <>
      <Box component={'header'} sx={s.headerWrapper}>
        {isPhone &&
          <Box ref={containerRef} onClick={handleToggle} sx={s.burgerWrapper}>
            <MenuIcon/>
          </Box>
        }
        <Box sx={s.logoWrapper}>
          {(!drawerVariant || isMobileDevice) &&
            <Link to='/'> {isMobileDevice ? <LogoIcon /> : <Logo />}  </Link>
          }
        </Box>

        <Box sx={s.headerContent(drawerVariant === 'Profile' ? 'left' : 'right')} ref={containerRef}>
            {!isPhone &&
              <Box sx={s.navMenu}>
                {menuItems.map(link =>
                  <MenuLink key={link.path} label={link.label} path={link.path} disabled={link.disabled}/>
                )}
              </Box>
            }
        </Box>
        <UserInfo />

        <Box sx={{ display: 'flex', gap: '16px' }}>
          <Select
            onChange={setLanguage}
            variant='standard'
            value={lang}
            sx={{
              height: '50px',
              width: '100px',
              borderBottom: 'none',
              ml: '10px',
              margin: '10px',
              color: '#A4ABB6',
              fontSize: '14px',
              fontWeight: 600,
              lineHeight: '20.8px',
              letterSpacing: '-1px',
              '& .MuiSelect-icon': { color: '#A4ABB6', mr: '15px' },
              '& .Mui-focused': { color: '' },
              '& .MuiSelect-select:focus': {
                backgroundColor: 'transparent',
                outline: 'none',
              },
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  mt: '5px',
                  bgcolor: '#FFFFFF',
                  fontSize: '12px',
                },
              },
            }}
            inputProps={{
              sx: {
                fontSize: '14px', // Размер текста внутри Select
                padding: '5px 25px 5px', // Внутренние отступы
              },
            }}
            disableUnderline
          >
            <MenuItem
              value='en'
              sx={{ display: 'flex', justifyContent: 'center', fontSize: '13px'}}
            >
              EN
            </MenuItem>
            <MenuItem
              value='ru'
              sx={{ display: 'flex', justifyContent: 'center', fontSize: '13px',  }}
            >
              RU
            </MenuItem>
          </Select>
        </Box>
      </Box>

      <Menu
        autoFocus={false}
        id='nav-menu'
        open={!!anchorEl && isPhone}
        onClick={handleToggle}
        anchorEl={anchorEl}
        MenuListProps={{
          disablePadding: true,
          sx: s.menuList,
        }}
        PaperProps={{
          elevation: 2,
          sx: s.navMenuPaper,
        }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {menuItems.map(link =>
          <MobileMenuLink key={link.label} label={link.label} path={link.path} disabled={link.disabled}/>
        )}
      </Menu>
    </>
  )
}

export default Header
