import { SyntheticEvent, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Box, Paper, Tab, Tabs } from '@mui/material'

import Preloader from 'components/Preloader'
import TabHistory from 'components/TabHistory'
import TabWithdraws from 'components/TabWithdraws'
import TabPanel from 'components/TablePanel'
import WalletInfo from 'components/WalletInfo'
import MobileFilter from 'components/Filter/MobileFilterButton'

import { useMediaSize } from 'hooks/useMediaSize'
import { useFetchAllWalletsQuery } from 'services/wallets'
import { useGetCurrencyQuery } from 'services/currency'

import s from './styles'
import { useTranslation } from 'react-i18next'


const Dashboard = () => {
  const { t } = useTranslation()
  const { isMobileDevice, isPhone } = useMediaSize()
  const [filterModal, setFilterModal] = useState(false)
  const [countOfFilters, setCountOfFilters] = useState(0)
  const [value, setValue] = useState(0)

  const [searchParams] = useSearchParams()
  const wallet = searchParams.get('wallet')
  const currency = searchParams.get('currency')
  const excludeEmpty = searchParams.get('excludeEmpty') === 'true'

  const {isLoading, data: wallets } = useFetchAllWalletsQuery(currency ? { currency, excludeEmpty: excludeEmpty } : skipToken, {refetchOnMountOrArgChange: true})
  const { data: currencyInfo, isLoading: isCurrencyLoading } = useGetCurrencyQuery(currency || skipToken)

  const handleTabsChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if (isLoading || isCurrencyLoading || (wallets?.length && !wallets?.find(w => w.id === wallet))) return <Preloader withLeftMargin/>

  return (
    <>
      <Outlet />
      <Box width='100%'>
        {wallet && wallets?.length ?
          <WalletInfo wallets={wallets} currency={currencyInfo}/>
          :
          <Paper sx={s.wrapper}>
            To get started, add a wallet
          </Paper>
        }
        <Box
          sx={s.adaptiveContainer}
          position='relative'
        >
          {wallet && (
          <Box sx={s.tableWrapper}>
            <Box flexGrow='1' width={'100%'}>
              <Box display='flex'>
                <Box width={{ xs: '100%', md: 'max-content' }}>
                  <Tabs
                    centered={isMobileDevice}
                    variant={isMobileDevice ? 'fullWidth' : 'standard'}
                    value={value}
                    onChange={handleTabsChange}
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      marginBottom: '16px',
                      overflow: 'hidden'
                    }}
                  >
                    <Tab
                      label={t('AccountHistory')}
                      sx={{ minWidth: { xs: '100px', md: '200px' } }}
                    />
                    <Tab
                      label={t('Withdrawals')}
                      sx={{ minWidth: { xs: '100px', md: '200px' } }}
                    />
                  </Tabs>
                </Box>
                {isPhone && <MobileFilter countOfFilters={countOfFilters} setFilterModal={() => setFilterModal(true)} />}
              </Box>
              <TabPanel value={value} index={0}>
                <TabHistory filterModal={filterModal} setCountOfFilters={setCountOfFilters} setFilterModal={setFilterModal}/>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <TabWithdraws filterModal={filterModal} setCountOfFilters={setCountOfFilters} setFilterModal={setFilterModal}/>
              </TabPanel>
            </Box>
          </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Dashboard