import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'

import BackButton from 'components/BackButton'
import Preloader from 'components/Preloader'
import StripeDialog from 'components/StripeDialog'
import AccountTransfer from 'pages/transfer/AccountTransfer'
import SuccessModal from 'components/SuccessModal'
import SecurityModal from 'components/SecurityModal'

import { useMediaSize } from 'hooks/useMediaSize'
import { useTransferExternalMutation, useTransferInternalMutation } from 'services/operations'
import { useFetchAllWalletsQuery, useGetWalletQuery } from 'services/wallets'
import { useFetchUserProfileQuery } from 'services/user'
import { useFetchAllCurrenciesQuery } from 'services/dictionaries'
import { TransferReqData, Verification } from 'types/requests'
import { toastError } from 'utils/toast'


const Transfer = () => {
  const { isPhone } = useMediaSize()

  const [searchParams] = useSearchParams()
  const walletId = searchParams.get('wallet')
  const currencyCode = searchParams.get('currency') || ''
  const [modal, setModal] = useState<'SUCCESS_MODAL' | 'SECURITY_MODAL' | null>(null)
  const [transferData, setTransferData] = useState<TransferReqData | null>(null)

  const [transferExternal, {isLoading: isTransferExternalLoading}] = useTransferExternalMutation()
  const [transferInternal, {isLoading: isTransferInternalLoading}] = useTransferInternalMutation()


  const { data: wallets, isLoading: isLoadingWallets, refetch, isFetching } = useFetchAllWalletsQuery({currency: currencyCode}, {refetchOnMountOrArgChange: true, refetchOnReconnect: true})
  const { data: user, isLoading: isLoadingUser } = useFetchUserProfileQuery()
  const { data: currencies = [] } = useFetchAllCurrenciesQuery()
  const { data: wallet, isLoading: isLoadingWallet } = useGetWalletQuery(
    walletId ?? '',
    {
      skip: !walletId,
    }
  )

  const currency = currencies?.find((c)=>(c.code === currencyCode))

  const isLoading = isLoadingWallets || isLoadingWallet || isLoadingUser || !wallets || !wallet || !currency || !user

  const is2faEnabled = !!user?.multiFA?.type && user?.multiFA?.type !== 'none'

  const handleTransferSubmit = (data: TransferReqData) => {
    data.recipient ?
    transferExternal(data)
      .unwrap()
      .then(() => {
        setModal('SUCCESS_MODAL')
      })
      .catch((e) =>
        toastError(e.data.reason || 'Something went wrong')
      )
      :
      transferInternal(data)
        .unwrap()
        .then(() => {
          setModal('SUCCESS_MODAL')
        })
        .catch((e) =>
          toastError(e.data.reason || 'Something went wrong')
        )
  }
  const handleAccountSubmit = (data: TransferReqData) => {
    if (!data) return
    if (is2faEnabled) {
      setTransferData(data)
      setModal('SECURITY_MODAL')
    } else {
      handleTransferSubmit(data)
    }
  }

  const handleSecuritySubmit =  (verification: Verification) => {
    if (!transferData) return
    console.log('verification ', verification)
    const data = { ...transferData, verification }
    handleTransferSubmit(data)
  }

  const handleCloseSuccessModal = () => {
    setModal(null)
    refetch()
  }

  if (isLoading) return <Preloader />

  return (
    <>
      <Container maxWidth={isPhone ? 'sm' : 'lg'} disableGutters>
        <BackButton/>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: {xs: 'center', md: 'start'},
            ml: {sm: 0, md: 9}
          }}
        >
          <AccountTransfer
            wallet={wallet}
            wallets={wallets}
            currency={currency}
            handleTransferSubmit={handleAccountSubmit}
          />
        </Box>
        <StripeDialog
          walletType={wallet.type}
          connectedAccountId={
            user.businessSettings.paymentSettings.stripe.connectedAccountId
          }
        />
      </Container>
      {(isFetching || isTransferExternalLoading || isTransferInternalLoading) && <Preloader/>}
      {modal === 'SUCCESS_MODAL' && <SuccessModal onClose={handleCloseSuccessModal}/>}
      {modal === 'SECURITY_MODAL' && (
        <SecurityModal
          handleSecuritySubmit={handleSecuritySubmit}
          loading={isTransferExternalLoading || isTransferInternalLoading}
          onClose={() => setModal(null)}
          isFetching={isFetching}
        />
      )}
    </>
  )
}

export default Transfer