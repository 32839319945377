import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Divider } from '@mui/material'
import Box from '@mui/material/Box'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'

import Modal from 'components/Modal'
import Preloader from 'components/Preloader'
import CircularProgressTimer from 'components/CircularProgressTimer'

import { useMediaSize } from 'hooks/useMediaSize'
import { Verification } from 'types/requests'
import { securitySchema } from './validation'


interface Props {
  handleSecuritySubmit: (data: Verification) => void
  isFetching: boolean
  loading: boolean
  onClose: () => void
  withSessionInfo?: boolean
}

interface FormValues {
  code: string
}

const SecurityModal: FC<Props> = ({
  handleSecuritySubmit,
  isFetching,
  onClose,
  loading,
}) => {
  const { isMobileDevice } = useMediaSize()

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(securitySchema),
    defaultValues: { code: '' },
  })

  const onSubmit = (formData: FormValues) => {
    const data = {
      code: formData.code,
      request: '',
    }
    handleSecuritySubmit(data)
  }

  return (
    <Modal
      open
      onClose={onClose}
      handleClose={onClose}
      title='Enter code from your phone'
    >
      {isFetching && <Preloader />}
      <Box
        component='form'
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          maxWidth: '465px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Controller
          control={control}
          name='code'
          render={({ field: { onChange, value, name } }) => (
            <Paper
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
                height: 56,
                borderRadius: '6px',
                border: '1px solid #79747E',
                boxShadow: 'none',
              }}
              style={{
                borderColor: errors?.code ? '#d32f2f' : '#79747E',
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder='Security Code'
                name={name}
                value={value}
                onChange={onChange}
                error={!!errors[name]}
                fullWidth={isMobileDevice}
              />

                {/* // right now use only Google 2fa, & don`t need to show lifetime code */}
                  <Divider
                  sx={{ height: 40, m: 0.5, color: '#262626' }}
                  orientation='vertical'
                  />
                  <CircularProgressTimer/>
                </Paper>
              )}
            />
          {/* <Box sx={{ marginLeft: '13px' }}>{isValid && <Success />}</Box> */}
          {/* <Box sx={{ color: '#d32f2f' }}>{errors?.code?.message}</Box>
            // right now use only Google 2fa, & don`t need to show session
            <Typography sx={{alignSelf: 'start', mt: 1, fontWeight: 600}}>Your session: 23234324</Typography>
          </Box> */}
          {/* {withSessionInfo && <Typography sx={{alignSelf: 'start', mt: 1, fontWeight: 600}}>Your session: 23234324</Typography>} */}
        <Button
          variant='contained'
          type='submit'
          disabled={!isValid || isFetching || loading}
          fullWidth
          sx={{mt: 4}}
        >
          Confirm
        </Button>
        </Box>
    </Modal>
  )
}

export default SecurityModal
