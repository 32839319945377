import { Box, Container, Paper, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import Preloader from 'components/Preloader'
import StripeDialog from 'components/StripeDialog'
import BackButton from 'components/BackButton'
import Account from './Account'
import Details from './Details'
import SuccessModal from 'components/SuccessModal'
import SecurityModal from 'components/SecurityModal'

import { AppRoute } from 'assets/const'
import { useMediaSize } from 'hooks/useMediaSize'
import { useWithdrawData } from 'hooks/useWithdrawData'
import { createCustomListener } from 'providers/listeners'
import { useWithdrawMutation } from 'services/operations'
import { useCreateTemplateMutation } from 'services/templates'
import {
  updateSavedTemplateFailedAction,
  updateSavedTemplateSuccessAction,
  withdrawFailedAction,
  withdrawSuccessAction,
} from 'store/actions'
import { Verification, WithdrawReqData } from 'types/requests'
import {
  PSDefaultTemplate,
  PSTemplateRequest,
  PaymentFormDefinitionModel,
} from 'types/template'
import { toastError } from 'utils/toast'


const Withdraw = () => {
  const { isPhone } = useMediaSize()

  const [isFetchingWithdraw, setIsFetchingWithdraw] = useState<boolean>(false)
  const [withdrawData, setWithdrawData] = useState<WithdrawReqData | null>(null)
  const [activeTemplate, setActiveTemplate] = useState<PSDefaultTemplate | PSTemplateRequest | null>(null)
  const [modal, setModal] = useState<'SUCCESS_MODAL' | 'SECURITY_MODAL' | null>(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const walletId = searchParams.get('wallet') || ''

  const {
    currency,
    user,
    wallet,
    defaultTemplates,
    savedTemplates,
    isLoading,
    refetchSavedTemplates,
  } = useWithdrawData()
  const [withdraw, {isLoading: isWithdrawLoading}] = useWithdrawMutation()
  const [createTemplate, {isLoading: isTemplateLoading}] = useCreateTemplateMutation()
  const is2faEnabled = !!user?.multiFA?.type && user?.multiFA?.type !== 'none'

  useEffect(() => {
    if (!activeTemplate) return
    if (Array.isArray(activeTemplate.paymentFormDefinition)) {
      //это значит выбрали дефолтный темплейт( не из сохраненных) и делать ничего не нужно
      return
    }
    const foundTemplate = defaultTemplates?.find(
      (t) => t.code === activeTemplate.code
    )

    if (!foundTemplate) {
      toastError('Template not found')
      return
    }

    const template: PSDefaultTemplate = {
      id: activeTemplate.id,
      name: activeTemplate.name,
      code: activeTemplate.code,
      psCode: foundTemplate.psCode,
      paymentFormDefinition: foundTemplate.paymentFormDefinition,
      values: activeTemplate.paymentFormDefinition,
    }

    setActiveTemplate(template)
  }, [activeTemplate])

  useEffect(() => {
    // Listener for failed withdrawal action
    const unsubscribeWithdrawFailed = createCustomListener({
      actionType: withdrawFailedAction.type,
      effect: () => {
        setIsFetchingWithdraw(false)
        toast.error('Something went wrong')
      },
    })

    // Listener for successful withdrawal action
    const unsubscribeWithdrawSuccess = createCustomListener({
      actionType: withdrawSuccessAction.type,
      effect: () => {
        setIsFetchingWithdraw(false)
        setModal('SUCCESS_MODAL')
      },
    })

    // Listener for successful template save action
    const unsubscribeUpdateTemplateSuccess = createCustomListener({
      actionType: updateSavedTemplateSuccessAction.type,
      effect: () => {
        refetchSavedTemplates()
          .unwrap()
          .finally(() => {
            toast.success('Template successfully saved')
            setIsFetchingWithdraw(false)
          })
      },
    })

    // Listener for failed template save action
    const unsubscribeUpdateTemplateFailed = createCustomListener({
      actionType: updateSavedTemplateFailedAction.type,
      effect: () => {
        refetchSavedTemplates()
          .unwrap()
          .finally(() => {
            toast.error('Something went wrong')
            setIsFetchingWithdraw(false)
          })
      },
    })

    return () => {
      unsubscribeWithdrawFailed()
      unsubscribeWithdrawSuccess()
      unsubscribeUpdateTemplateSuccess()
      unsubscribeUpdateTemplateFailed()
    }
  }, [])


  const handleWithdrawSubmit = (data: WithdrawReqData) => {
    if (!data) return
    if (!is2faEnabled) {
      withdraw(data).unwrap().then(() => setIsFetchingWithdraw(true))
    } else {
      setWithdrawData(data)
      setModal('SECURITY_MODAL')
    }
  }

  const handleCreateTemplate = (templateToSave: PaymentFormDefinitionModel) => {
    if (activeTemplate) {
      createTemplate({
        ...activeTemplate,
        paymentFormDefinition: templateToSave,
      }).unwrap().then(() => setIsFetchingWithdraw(true))
    }
  }

  const handleSecuritySubmit = (verification: Verification) => {
    console.log('verification ', verification)
    if (!withdrawData) return
    const data = { ...withdrawData, verification }
    withdraw(data)
      .unwrap()
      .then(() => {
        setIsFetchingWithdraw(true)
        setModal(null)
      })
      .catch(() => {})
  }

  const onCloseSuccessModal = useCallback(()=> {
    setModal(null)
    navigate(
      {
        pathname: "/",
        search: searchParams.toString()
      }
    );
  }, [])

  if (isLoading) {
    return <Preloader />
  }

  if (!defaultTemplates || !wallet || !currency) {
    return <Navigate to={`/${AppRoute.NotFound}`} />
  }

  return (
    <>
      <Container maxWidth={isPhone ? 'sm' : 'lg'} disableGutters sx={{display: 'flex', flexDirection: 'column'}}>
        <BackButton />
        <Paper
          sx={{
            p: {xs: 2, sm: 3},
            maxWidth: '661px',
            alignSelf: {xs: 'center', md: 'start'},
            ml: {sm: 0, md: 9}
          }}>
          <Typography variant={isPhone ? 'h3' : 'h2'}>Create withdraw request</Typography>
          <Box sx={{ mt: '12px'}}>
            <Typography fontSize={14}>
              Clever Wallet Cashout Hours are Monday through Friday 9am to 5pm EST
              excluding US Holidays. Our team will work as quickly as possibly to
              get your funds in your hands, but please understand that some cashouts
              may require manual review which may lead to a delay. As always, thank
              you for your patience!
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: 3,
              mx: { xs: 'auto', md: '0' },
            }}
          >
            <Account
              defaultTemplates={defaultTemplates}
              savedTemplates={savedTemplates || []}
              activeTemplate={activeTemplate}
              setActiveTemplate={setActiveTemplate}
            />
            { activeTemplate && (
              <Details
                handleWithdrawSubmit={handleWithdrawSubmit}
                activeTemplate={activeTemplate as PSDefaultTemplate}
                wallet={wallet}
                currency={currency}
                isFetching={isFetchingWithdraw}
                isLoadingWithdrawButton={isWithdrawLoading}
                isLoadingTemplateButton={isTemplateLoading}
                handleCreateTemplate={handleCreateTemplate}
              />
            )}
          </Box>
          <StripeDialog
            walletType={wallet.type}
            connectedAccountId={
              user?.businessSettings?.paymentSettings?.stripe?.connectedAccountId
            }
            isOperationAllowed={
              user?.businessSettings?.paymentSettings?.stripe?.canPayout
            }
          />
        </Paper>
      </Container>
      {modal === 'SECURITY_MODAL' && (
        <SecurityModal
          isFetching={isFetchingWithdraw}
          handleSecuritySubmit={handleSecuritySubmit}
          loading={isWithdrawLoading}
          onClose={() => setModal(null)}
        />
      )}
      {modal === 'SUCCESS_MODAL' && (<SuccessModal onClose={onCloseSuccessModal}/>)}
    </>
  )
}

export default Withdraw