import { yupResolver } from '@hookform/resolvers/yup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { DefaultValues, FieldValues, Path, useForm } from 'react-hook-form'
import { AnyObjectSchema } from 'yup'

import DatePicker from '../DatePicker'
import Select from '../Select'
import TextInput from '../TextInput'
import FilterChip from './FilterChip'

import { useAppDispatch, useAppSelector } from 'hooks'
import {
  getIsFilterExpended,
  toggleIsFilterExpended,
} from 'store/dashboard-reducer'
import { OperationsFilterParams, WithdrawFilterParams } from 'types/operation'

import s from './styles'
import { useTranslation } from 'react-i18next'


interface Props<T extends OperationsFilterParams & WithdrawFilterParams> {
  params: T
  statusOptions: { label: string; value: string }[]
  selectOptions: { label: string; value: string }[] | []
  selectLabel: string
  selectName: string
  schema: AnyObjectSchema
  clearParams: () => void
  setParams: (params: T) => void
}

const Filter = <T extends OperationsFilterParams & WithdrawFilterParams>({
  params,
  statusOptions,
  selectOptions,
  selectLabel,
  selectName,
  schema,
  setParams,
  clearParams,
}: Props<T>) => {
  const { t } = useTranslation()
  const isFilterExpended = useAppSelector(getIsFilterExpended)
  const dispatch = useAppDispatch()
  const isAmountFilter = Object.hasOwn(params, 'maxAmount')
  const isOperationLabel = selectLabel === 'Operations'

  const {
    handleSubmit,
    control,
    reset,
    resetField,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: params as DefaultValues<T>,
  })

  const resetHandler = () => {
    clearParams()
    reset()
  }

  const onSubmit = (values: FieldValues) => {
    setParams(values as T)
  }

  const handleChange = () => {
    dispatch(toggleIsFilterExpended())
  }

  const handleDelete = (name: Path<T>) => {
    resetField(name)
    onSubmit(watch())
  }

  return (
    <Accordion
      elevation={0}
      disableGutters
      expanded={isFilterExpended}
      onChange={handleChange}
      sx={s.accordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Box sx={s.header}>
          <Typography sx={s.headerTitle}> {t('Filter')} </Typography>
          {!isFilterExpended && Object.entries(params).map(([key, value]) =>
            value && <FilterChip key={key} name={key} value={value} handleDelete={() => handleDelete(key as Path<T>)}/>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            columns={{ xs: 1, sm: 6, md: isOperationLabel ? 8 : 6 }}
            columnSpacing='12px'
          >
            <Grid item sm={3} md={isOperationLabel ? 1 : 1.5} mb={{sm: 3, md: 0}}>
              <Select
                control={control}
                name={selectName}
                label={selectLabel}
                options={selectOptions}
                errors={errors}
              />
            </Grid>
            <Grid item sm={3} md={1}>
              <Select
                control={control}
                name='status'
                label='Status'
                options={statusOptions}
                errors={errors}
              />
            </Grid>
            <Grid item sm={isOperationLabel ? 1 : 2} md={1}>
              <DatePicker
                name='dateFrom'
                label='From'
                control={control}
                errors={errors}
              />
            </Grid>
            <Grid item sm={isOperationLabel ? 1 : 2} md={1}>
              <DatePicker
                name='dateTo'
                label='Till'
                control={control}
                errors={errors}
              />
            </Grid>
            {isAmountFilter && (
              <>
                <Grid item sm={1} md={1}>
                  <TextInput
                    control={control}
                    name='minAmount'
                    label='Min amount'
                    errors={errors}
                    FormHelperTextProps={{
                      sx: { mr: { md: 'auto' }, ml: { md: 'auto' } },
                    }}
                  />
                </Grid>
                <Grid item sm={1} md={1}>
                  <TextInput
                    control={control}
                    name='maxAmount'
                    label='Max amount'
                    errors={errors}
                    FormHelperTextProps={{
                      sx: { mr: { md: 'auto' }, ml: { md: 'auto' } },
                    }}
                  />
                </Grid>
              </>
            )}

            <Grid item sx={s.buttonWrapper} sm={2} md={isOperationLabel ? 2 : 1.5}>
              <Button
                disabled={!isValid || !isDirty}
                variant='contained'
                sx={s.button}
                type='submit'
              >
                Apply
              </Button>

              <Button
                type='reset'
                variant='outlined'
                onClick={resetHandler}
                sx={s.button}
              >
                Clear
              </Button>
            </Grid>
          </Grid>

        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default Filter
